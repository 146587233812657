import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { css } from "@emotion/core"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: { extension: { regex: "/(jpg)|(png)|(jpeg)|(svg)/" } }
        ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath === props.filename
      })
      if (!image) {
        return null
      }
      return (
        <Img
          alt={props.alt}
          fluid={image.node.childImageSharp.fluid}
          css={css`
            ${props.imgCss}
          `}
          imgStyle={{ objectFit: "cover" }}
        />
      )
    }}
  />
)

Image.propTypes = {
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string,
  imgCss: PropTypes.object,
}

export default Image
