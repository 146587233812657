/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { ViewportProvider } from "../contexts/viewContext"
import Header from "./header"
import "./layout.css"

const Layout = ({ title, children }) => {
  const [prevScroll, setPrevScroll] = React.useState(0)
  const [hideNav, setHideNav] = React.useState(false)

  const handleScroll = () => {
    const changeY = window.pageYOffset - prevScroll
    if (changeY > 1 && window.pageYOffset > 0) {
      setHideNav(true)
    } else {
      setHideNav(false)
    }

    setPrevScroll(window.pageYOffset)
  }
  
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })

  return (
    <ViewportProvider>
      <Header title={title} hideNav={hideNav} />
      <main>{children}</main>
    </ViewportProvider>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
