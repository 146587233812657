/**
 * Nav bar
 */

import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"
import { breakpoint } from "./../constants"
import { useViewport } from "../contexts/viewContext"
import { Link } from "gatsby"
import Image from "./image"

const navbar = css`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  font-size: 0.12rem;
  top: 0;
  left: 0;
  width: 100vw;
  height: 0.3rem;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  line-height: 0.3rem;
  transition: transform 0.5s;

  @media (max-width: ${breakpoint}px) {
    height: 1rem;
    justify-content: flex-end;
    line-height: 1rem;
  }
`

const tour = css`
  cursor: pointer;
  color: white;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const logo = css`
  width: 0.6rem;
  height: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 0.1rem;
  p {
    margin: 0 0.04rem;
    text-transform: uppercase;
    font-size: 0.08rem;
    color: #fff;
  }
`

const logoWrapper = css`
  width: 0.2rem;
  height: 0.2rem;
`
const icon = css`
  width: autos;
  height: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  p {
    margin: 0 0.04rem;
    text-transform: uppercase;
    font-size: 0.08rem;
    color: #fff;
  }
`

const iconWrapper = css`
  width: 0.15rem;
  height: 0.15rem;
`
const tourIconWrapper = css`
  width: 0.1rem;
`

const iconCollection = css`
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
`
// navTitle looks like is not been use anymore
/* const navTitle = css`
  text-transform: uppercase;
  color: white;
  font-size: 0.12rem;
  @media (max-width: ${breakpoint}px) {
    font-size: 0.36rem;
    margin: 0 auto;
  } 
`
*/

const burgerIcon = css`
  position: relative;
  width: 0.6rem;
  height: 3px;
  background-color: #fff;
  outline: none;

  &:before,
  &:after {
    content: "";
    width: 0.6rem;
    height: 3px;
    background-color: #fff;
    position: absolute;
    left: 0;
  }
  &:before {
    top: -0.2rem;
  }
  &:after {
    top: 0.2rem;
  }
`

const subNav = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 10rem;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 200;
  transition: 0.3s transform;
  transform: translateY(-100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const closeIcon = css`
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 0.2rem;
  top: 0.2rem;
  outline: none;
`

const menuItem = css`
  color: #fff;
  font-size: 0.5rem;
  text-decoration: none;
  margin: 0.2rem 0;
  text-transform: uppercase;
  outline: none;
`

const line = css`
  border-top: 1px solid #999;
  width: 60vw;
`

const Line = () => <div css={line}></div>

// SubNav only used in mobile version.
const SubNav = ({ toggleNav, showSubNav }) => {
  const homeUrl = "/"
  const castleUrl = "/castle"
  const classUrl = "/class"
  const genderUrl = "/gender"
  const tourUrl = "/tour"
  return (
    <div
      css={css`
        ${subNav};
        ${showSubNav && "transform: translateY(0)"}
      `}
    >
      <div
        css={closeIcon}
        role="button"
        tabIndex={0}
        onKeyDown={toggleNav}
        onClick={toggleNav}
      >
        <Image filename="assets/nav-bar/close.png" />
      </div>
      <Link css={menuItem} to={homeUrl}>
        HOME
      </Link>
      <Line />
      <Link css={menuItem} to={castleUrl}>
        the castle
      </Link>
      <Link css={menuItem} to={genderUrl}>
        the women & the men
      </Link>
      <Link css={menuItem} to={classUrl}>
        the classes
      </Link>
      <Line />
      <Link to={tourUrl} css={menuItem}>
        tour
      </Link>
    </div>
  )
}

// Header is nav-bar
const Header = ({ title, handleClick, hideNav }) => {
  const [showSubNav, setShowSubNav] = React.useState(false)
  const [hideHead, setHideHead] = React.useState(false)

  const toggleNav = () => {
    setShowSubNav(!showSubNav)
    setHideHead(!hideHead)
  }

  const { width } = useViewport()
  return (
    <header>
      <div
        css={css`
          ${navbar};
          ${(hideNav || hideHead) && "transform: translateY(-100%);"}
        `}
      >
        {width > breakpoint ? (
          <Link css={logo} to="/">
            <div css={logoWrapper}>
              <Image filename="assets/nav-bar/logo.png" />
            </div>
            <p css={css`${title === "home" && "text-decoration: underline; text-decoration-color: #fff"}`}>home</p>
          </Link>
        ) : null}
        {width > breakpoint ? (
          <div css={iconCollection}>
            <Link css={icon} to="/castle">
              <div css={iconWrapper}>
                <Image filename="assets/castle.png" />
              </div>
              <p css={css`${title === "castle" && "text-decoration: underline; text-decoration-color: #809CD0"}`}>the castle</p>
            </Link>
            <Link css={icon} to="/gender">
              <div css={iconWrapper}>
                <Image filename="assets/gender.png" />
              </div>
              <p css={css`${title === "gender" && "text-decoration: underline; text-decoration-color: #9C251B"}`}>the women & the men</p>
            </Link>
            <Link css={icon} to="/class">
              <div css={iconWrapper}>
                <Image filename="assets/class.png" />
              </div>
              <p css={css`${title === "class" && "text-decoration: underline; text-decoration-color: #FDB452"}`}>the classes</p>
            </Link>
          </div>
        ) : null}
        <div css={tour}>
          {width > breakpoint ? (
            <Link
              css={icon}
              to="/tour"
            >
              <div css={tourIconWrapper}>
              <Image
                filename="assets/nav-bar/wayfind.png"
                imgCss={css`
                  width: 100%;
                `}
              />
              </div>
              <p css={css`${title === "tour" && "text-decoration: underline; text-decoration-color: #fff"}`}>tour</p>
            </Link>
          ) : (
            <div
              css={burgerIcon}
              role="button"
              tabIndex={0}
              onKeyDown={toggleNav}
              onClick={toggleNav}
              aria-label="menuBtn"
            />
          )}
        </div>
      </div>
      <SubNav
        toggleNav={toggleNav}
        showSubNav={showSubNav}
        handleClick={handleClick}
      />
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  hideNav: PropTypes.bool,
}

Header.defaultProps = {
  title: ``,
  hideNav: false,
}

export default Header
