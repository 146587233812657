/*
  This context is used to listen the client's width of viewpoint
*/

import React from "react"
const viewportContext = React.createContext({})

export const ViewportProvider = ({ children }) => {
  // also listen the height, but isn't been used
  const [width, setWidth] = React.useState(1024)
  const [height, setHeight] = React.useState(1024)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    document.documentElement.style.fontSize =
      (window.innerWidth / 750) * 100 + "px"
  }

  React.useEffect(() => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    document.documentElement.style.fontSize =
      (window.innerWidth / 750) * 100 + "px"
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  )
}

export const useViewport = () => {
  const { width, height } = React.useContext(viewportContext)
  return { width, height }
}
